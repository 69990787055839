// IMPORTS
@import (reference) "../settings.less";


// ICON BANNERS
.icon-banners-wrapper {
    margin-top: calc(-0.5 * var(--rowGap));
    display: flex;
    justify-content: space-around;
    position: relative;
    gap: 32px;

    &:has(.icon-banner:nth-child(3)) {
        justify-content: space-between;
    }


    &:has(.icon-banner:nth-child(4)) {
        .grid();
        grid-template-columns: repeat(4, 1fr);

        .icon-banner {
            max-width: none;
        }
    }

    &:has(.icon-banner:nth-child(5)) {
        display: flex;
        flex-wrap: wrap;
    }

    &:has(.icon-banner:nth-child(6)) {
        .grid();
        grid-template-columns: repeat(3, 1fr);
    }

    &:has(.icon-banner:nth-child(7)) {
        display: flex;
    }

    &:has(.icon-banner:nth-child(8)) {
        .grid();
        grid-template-columns: repeat(4, 1fr);
    }
}

.icon-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 50%;
    border: 2px solid @border-color;
    padding: 18px;
    position: relative;
    max-width: 29%;
    flex: 1;
    .corners();

    figure {
        width: 48px;
        aspect-ratio: 1;
        margin-bottom: 9px;

        img {
            .img-contain();
        }
    }

    b {
        font-weight: 700;
        color: @header-color;
        text-transform: uppercase;
        font-size: 14px;
    }

    span {
        font-size: 14px;
        font-size: 12px;
    }
}

// BLOG BANNER
.blog-banners-section {
    position: relative;
    padding: calc(2/3 * var(--rowGap)) 0;

    &:before {
        content: '';
        background: @light-bg;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 101vw;
        height: 100%;
        z-index: -1;
    }
}

.blog-banners-wrapper {
    .grid();
    grid-template-columns: repeat(3, 1fr);
    gap: 56px 30px;
    position: relative;
}

.blog-banner {
    .grid();
    grid-template-rows: auto auto 1fr;
    gap: 10px;
    width: 100%;

    &:hover {

        b {
            text-decoration: underline;
        }
    }

    .img-wrapper {
        margin-bottom: 14px;
        width: 100%;
        aspect-ratio: ~"460/220";

        img {
            .img-crop();
        }
    }

    b {
        color: @header-color;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span {
        color: @font-color;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        height: ~"calc(3 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .confirm-button {
        margin-top: 14px;
        font-style: normal;
        height: 29px;
        align-self: end;
    }
}

// SIDE BORDERS
.side-borders {

    figure {
        position: relative;

        &:after {
            width: 8px;
            height: 100%;
            background: @main-color;
            top: 0;
            position: absolute;
            z-index: 1;
        }
    }

    @media (min-width: 601px) {

        figure:after {
            width: 4px;
        }

        &:nth-child(3n + 1) figure:after {
            left: 0;
            content: '';
        }

        &:nth-child(3n + 3) figure:after {
            right: 0;
            content: '';
        }
    }

    @media (max-width: 600px) {
        &:nth-child(2n + 1) figure:after {
            left: 0;
            content: '';
        }

        &:nth-child(2n) figure:after {
            right: 0;
            content: '';
        }
    }
}

.rec-banners-wrapper .side-borders {
    @media (max-width: 420px) {

        figure {

            &:before,
            &:after {
                content: '';
                width: 4px;
                height: 100%;
                background: @main-color;
                top: 0;
                position: absolute;
                z-index: 1;
            }

            &:before {
                right: initial;
                left: 0;
            }

            &:after {
                left: initial;
                right: 0;
            }
        }
    }
}